<template lang="pug"></template>

<script>
  import withWebSocket from "@/mixins/withWebSocket"
  import { showToast } from "@/helpers/toasts"

  export default {
    mixins: [withWebSocket],

    mounted() {
      this.webSocketSubscribe("OtaAccountChannel", {
        received: this.handleMessages
      })
    },

    methods: {
      handleMessages(data) {
        const { ota_account_disabled: otaDisabled, ota_name: otaName } = data
        if (otaDisabled) {
          showToast({
            title: this.$t("ota_accounts.toast.disabled.title"),
            text: this.$t("ota_accounts.toast.disabled.message", { ota_name: this.$t(`ota.${otaName}`) }),
            autoHide: false
          })
        }
      }
    }
  }
</script>
